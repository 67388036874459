import {
  createSettingsParams,
  ISettingsParam,
  PartialBy,
} from '@wix/tpa-settings';
import timetableSettingsParams from '../WeeklyTimetable/settingsParams';
import { LayoutOptions } from '../../types/types';
import { ISettingsParams } from '../BookingCalendar/settingsParams';

type DailyAgendaSettingsOverride = Pick<
  ISettingsParams,
  'calendarLayout' | 'maxTimeSlotsDisplayedPerDay' | 'limitTimeSlotsDisplay'
>;

type IDailyAgendaSettingsParamsDefinitions = {
  [key in keyof DailyAgendaSettingsOverride]: PartialBy<
    ISettingsParam<DailyAgendaSettingsOverride[key]>,
    'key'
  >;
};

export const dailyAgendaSettingsParamsDefinitions: IDailyAgendaSettingsParamsDefinitions =
  {
    calendarLayout: {
      getDefaultValue: () => LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER,
    },
    maxTimeSlotsDisplayedPerDay: {
      getDefaultValue: ({ isMobile }) => (isMobile ? 1 : 6),
    },
    limitTimeSlotsDisplay: {
      getDefaultValue: () => true,
      inheritDesktop: true,
    },
  };

const dailyAgendaSettingsParams =
  createSettingsParams<DailyAgendaSettingsOverride>(
    dailyAgendaSettingsParamsDefinitions,
  );

export default {
  ...timetableSettingsParams,
  ...dailyAgendaSettingsParams,
};

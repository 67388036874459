import React, { useContext } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { HeaderViewModel } from '../../ViewModel/headerViewModel/headerViewModel';
import { st, classes } from './Header.st.css';
import Filters from './Filters/Filters';
import TimezoneSelection from '../TimezoneSelection/TimezoneSelection';
import { useSettingsParams } from '../../Hooks/useSettingsParams';
import { DataHooks, HEADER_TITLE_ID } from './constants';

export type HeaderProps = {
  viewModel: HeaderViewModel;
};

const Header: React.FC<HeaderProps> = ({ viewModel }) => {
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const {
    title,
    subtitle,
    filters,
    timezoneSelectionViewModel,
    customSubtitle,
    serviceTagLine,
  } = viewModel;
  const { experiments } = useExperiments();
  const isCalendarDisplayPerBreakpointEnabled = experiments.enabled(
    'specs.bookings.calendarDisplayPerBreakpoint',
  );
  return (
    <div
      data-hook={DataHooks.Root}
      className={st(classes.root, {
        isRTL,
        isMobile,
        alignment: settings.get(settingsParams.textAlignment),
        isCalendarDisplayPerBreakpointEnabled,
      })}
    >
      {title ? (
        <Text
          id={HEADER_TITLE_ID}
          data-hook={DataHooks.Title}
          className={classes.title}
          tagName={settings.get(settingsParams.headerTitleHtmlTag)}
        >
          {title}
        </Text>
      ) : null}
      {isCalendarDisplayPerBreakpointEnabled ? (
        <>
          {serviceTagLine && (
            <Text
              data-hook={DataHooks.tagLine}
              className={st(classes.subtitle, classes.serviceTagLine)}
              tagName={AccessibilityHtmlTags.Paragraph}
            >
              {serviceTagLine}
            </Text>
          )}
          {customSubtitle && (
            <Text
              data-hook={DataHooks.Subtitle}
              className={st(classes.subtitle, classes.customSubtitle)}
              tagName={AccessibilityHtmlTags.Paragraph}
            >
              {customSubtitle}
            </Text>
          )}
        </>
      ) : subtitle ? (
        <Text
          data-hook={DataHooks.Subtitle}
          className={st(classes.subtitle, classes.deprecatedSubtitle)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {subtitle}
        </Text>
      ) : null}
      <Filters filterViewModels={filters} />
      {timezoneSelectionViewModel ? (
        <TimezoneSelection viewModel={timezoneSelectionViewModel} />
      ) : null}
    </div>
  );
};

export default Header;
